import { Suspense, useEffect, useState } from 'react';
import { Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { adminConnectedWithCustomerAccount, BASE_URL_API, boatIsEdited, boatSelected, brandSelected, brandSelectedId, dateMaxOffreExcess, error, exportInformations, numbersPacksInCart, offreExcess, promoExcessActive, resumeCart, userConnected } from './recoil';
import Header from './components/Header/Header';
import HeaderUnlogged from './components/HeaderUnlogged/HeaderUnlogged';
import Footer from './components/Footer/Footer';
import Aside from './components/Aside/Aside';
import styles from './App.module.scss';
import FooterUnlogged from './components/FooterUnlogged/FooterUnlogged';
import Notification from './components/Notification/Notification';
import { getFetchAuthorization } from './utils/Auth';
import { brandChangeCss, choiceBrand } from './utils/Brand';
import { getDateLimiteOffreExcess, isLivraisonPlusDeTroisMois } from './utils/Helpers';

function App() {
  const user = useRecoilValue(userConnected);
  const setBoatSelected = useSetRecoilState(boatSelected);
  const boat = useRecoilValue(boatSelected);
  const setExportInformations = useSetRecoilState(exportInformations);
  const setResumeCart = useSetRecoilState(resumeCart);
  const setBrandSelectedId = useSetRecoilState(brandSelectedId);
  const URL_API = useRecoilValue(BASE_URL_API);
  const [isLoading, setIsLoading] = useState(true);
  const [err, setError] = useRecoilState(error);
  const navigate = useNavigate();
  const setBrandSelected = useSetRecoilState(brandSelected);
  const setQuantityPacks = useSetRecoilState(numbersPacksInCart);
  const editedBoat = useRecoilValue(boatIsEdited);
  const setOffreExcess = useSetRecoilState(offreExcess);
  const setDateMaxOffreExcess = useSetRecoilState(dateMaxOffreExcess);
  const [promotionExcessActive, setPromotionExcessActive] = useRecoilState(promoExcessActive);
  // const brand = useRecoilValue(brandSelected);
  // const [brand, setBrandSelected] = useRecoilState(brandSelected);

  // const brandUrl = useRecoilValue(brandSelected);

  useEffect(() => {
    if (err && err.status !== 200) {
      navigate('/oops');
    }
  }, [err]);

  useEffect(() => {
    async function getPromoActive() {
      try {
        const response = await fetch(`${URL_API}/promoActive/2`, {
          headers: {
            Authorization: getFetchAuthorization(),
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPromotionExcessActive(data.promoActive);
        } else {
          setError({ status: response.status, errorText: response.statusText, msg: data.msg });
        }
      } catch (e) {
        setError({ info: e });
      }
    }

    getPromoActive();
  }, []);

  useEffect(() => {
    async function fetchBoatSelected() {
      try {
        setIsLoading(true);
        const response = await fetch(`${URL_API}/projectSelected/${user.id}`, {
          headers: {
            Authorization: getFetchAuthorization(),
          },
        });

        const data = await response.json();

        if (response.ok) {
          setBoatSelected(data.boatSelected);
          setExportInformations(data.exportInformations);
          setResumeCart(data.resumeCart);
          if (data.boatSelected) {
            setBrandSelected(choiceBrand(data.boatSelected.brand));
            if (data.boatSelected.brand.toLowerCase() === 'excess' && isLivraisonPlusDeTroisMois(data.boatSelected.deliveryDate) && promotionExcessActive) {
              setOffreExcess(true);
              const dateMaxOffre = getDateLimiteOffreExcess(data.boatSelected.deliveryDate);
              setDateMaxOffreExcess(dateMaxOffre);
            } else {
              setOffreExcess(false);
            }
          } else {
            setBrandSelected('all-set');
          }
          if (data.resumeCart != null) {
            setQuantityPacks(data.resumeCart.quantityPacks);
          } else {
            setQuantityPacks(0);
          }
        } else {
          setError({ status: response.status, errorText: response.statusText, msg: data.msg });
        }
      } catch (e) {
        setError({ info: e });
      } finally {
        setIsLoading(false);
      }
    }

    if ((user && user.active)) {
      fetchBoatSelected();
    } else {
      setIsLoading(false);
    }

    if (boat) {
      setBrandSelected(choiceBrand(boat.brand));
    } else {
      setBrandSelected('all-set');
    }

    async function fetchBrandSelected() {
      try {
        const response = await fetch(`${URL_API}/brandSelected`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ brandSelected: window.location.hostname.split('.')[0] }),
        });

        const data = await response.json();

        if (response.ok) {
          setBrandSelectedId(data.brandSelectedId);
        }

        setError({ status: response.status, errorText: response.statusText, msg: data.msg });
      } catch (e) {
        setError({ info: e });
      }
    }

    fetchBrandSelected();
  }, [user, editedBoat]);

  return (
    <div className={boat ? brandChangeCss(boat.brand) : 'allset_brand'}>
      <Notification />
      {
        !isLoading && (
          <div className={styles.appContainer}>

            {
              (user && user.active) ? (
                <>
                  <Header />
                  <Aside />
                </>
              ) : (
                <HeaderUnlogged />

              )
              }

            <div className={(user && user.agent) ? styles.agent : styles.nonAgent}>
              {/* <ScrollRestoration /> */}
              <Suspense>
                <Outlet />
              </Suspense>
            </div>

            {user ? <Footer /> : <FooterUnlogged />}
          </div>
        )
      }
    </div>

  );
}

export default App;
