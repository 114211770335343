import { lazy, useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { adminConnectedWithCustomerAccount, BASE_URL_API, boatSelected, error, languageSelected, promoExcessActive, userConnected } from './recoil';

import App from './App';
import { getFetchAuthorization, getToken } from './utils/Auth';
import { UnloggedRoute, LoggedRoute, AgentRoute, BoatSelected } from './components/ProtectedRoute/ProtectedRoute';

const HomepageUnlogged = lazy(() => import('./pages/HomepageUnlogged/HomepageUnlogged'));
const Homepage = lazy(() => import('./pages/Homepage/Homepage'));
const RegisterPro = lazy(() => import('./pages/RegisterPro/RegisterPro'));
const Boats = lazy(() => import('./pages/Boats/Boats'));
const DashboardPro = lazy(() => import('./pages/DashboardPro/DashboardPro'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Pack = lazy(() => import('./pages/CategoryPacks/pages/Pack/Pack'));
const CategoryPacks = lazy(() => import('./pages/CategoryPacks/CategoryPacks'));
const Cart = lazy(() => import('./pages/Cart/Cart'));
const AddUser = lazy(() => import('./pages/Boats/pages/AddUser/AddUser'));
const Delivery = lazy(() => import('./pages/Cart/pages/Delivery/Delivery'));
const ErrorPage = lazy(() => import('./components/ErrorPage/ErrorPage'));
const Account = lazy(() => import('./pages/Account/Account'));
const Allset = lazy(() => import('./pages/About/Allset/Allset'));
const Team = lazy(() => import('./pages/About/Team/Team'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Faq = lazy(() => import('./pages/Faq/Faq'));
const OffrePromoExcess = lazy(() => import('./pages/OffrePromoExcess/OffrePromoExcess'));

function MyRoutes() {
  const URL_API = useRecoilValue(BASE_URL_API);
  const [user, setUser] = useRecoilState(userConnected);
  const setBoatSelected = useSetRecoilState(boatSelected);
  const [lang, setLang] = useRecoilState(languageSelected);
  const [isLoading, setIsLoading] = useState(true);
  const [router, setRouter] = useState('');
  const setError = useSetRecoilState(error);
  const setPromotionExcessActive = useSetRecoilState(promoExcessActive);
  const adminConnected = useRecoilValue(adminConnectedWithCustomerAccount);

  function updateRouter() {
    setRouter(createBrowserRouter([
      {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: 'signin',
            element: <UnloggedRoute><HomepageUnlogged /></UnloggedRoute>,
          },
          {
            path: 'new-agent',
            element: <UnloggedRoute><RegisterPro /></UnloggedRoute>,
          },
          {
            path: 'top-sales',
            element: <LoggedRoute><BoatSelected><Homepage /></BoatSelected></LoggedRoute>,

          },
          {
            index: true,
            element: <LoggedRoute><Boats /></LoggedRoute>,
          },
          {
            path: 'add-user',
            element: <LoggedRoute><AgentRoute><BoatSelected><AddUser /></BoatSelected></AgentRoute></LoggedRoute>,
          },
          {
            path: 'orders',
            element: <LoggedRoute><Dashboard /></LoggedRoute>,
          },
          {
            path: 'orders&commissions',
            element: <LoggedRoute><AgentRoute><DashboardPro /></AgentRoute></LoggedRoute>,
          },
          {
            path: 'pack/:id',
            element: <LoggedRoute><BoatSelected><Pack /></BoatSelected></LoggedRoute>,

          },
          {
            path: 'packs/:category',
            element: <LoggedRoute><BoatSelected><CategoryPacks /></BoatSelected></LoggedRoute>,
          },
          {
            path: 'cart',
            element: <LoggedRoute><BoatSelected><Cart /></BoatSelected></LoggedRoute>,
          },
          {
            path: 'delivery',
            element: <LoggedRoute><BoatSelected><Delivery /></BoatSelected></LoggedRoute>,
          },
          {
            path: 'account',
            element: <LoggedRoute><Account /></LoggedRoute>,
          },
          {
            path: 'allset',
            element: <Allset />,
          },
          {
            path: 'team',
            element: <Team />,
          },
          {
            path: 'contact',
            element: <Contact />,
          },
          {
            path: 'faq',
            element: <Faq />,
          },
          {
            path: 'offre-promo-excess',
            element: <LoggedRoute><OffrePromoExcess /></LoggedRoute>,
          },
          {
            path: 'oops',
            element: <ErrorPage />,
          },
        ],
      },
    ]));
  }

  useEffect(() => {
    async function getCurrentUser() {
      try {
        setIsLoading(true);
        const response = await fetch(`${URL_API}/currentUser`, {
          headers: {
            Authorization: getFetchAuthorization(),
          },
        });

        const data = await response.json();

        if (response.ok) {
          setUser(data.userConnected, updateRouter());
          setBoatSelected(data.boatSelected);
          setLang(data.userConnected.lang);
        } else {
          setError({ status: response.status, errorText: response.statusText, msg: data.msg });
          updateRouter();
        }
      } catch (e) {
        setError({ info: e });
      } finally {
        setIsLoading(false);
      }
    }

    async function getPromoActive() {
      try {
        const response = await fetch(`${URL_API}/promoActive/2`, {
          headers: {
            Authorization: getFetchAuthorization(),
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPromotionExcessActive(data.promoActive);
        } else {
          setError({ status: response.status, errorText: response.statusText, msg: data.msg });
        }
      } catch (e) {
        setError({ info: e });
      }
    }

    if (getToken()) {
      getCurrentUser();
      getPromoActive();
    } else {
      setIsLoading(false);
      updateRouter();
    }
  }, [adminConnected]);

  return !isLoading && <RouterProvider router={router} />;
}

export default MyRoutes;
