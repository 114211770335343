import { atom } from 'recoil';

export const BASE_URL_API = atom({
  key: 'BASE_URL_API',
  default: process.env.REACT_APP_URL_API,
});

export const userConnected = atom({
  key: 'userConnected',
  default: null,
});

export const userBoats = atom({
  key: 'userBoats',
  default: [],
});

export const boatSelected = atom({
  key: 'boatSelected',
  default: null,
});

export const brandSelectedId = atom({
  key: 'brandSelectedId',
  default: null,
});

export const resumeCart = atom({
  key: 'resumeCart',
  default: null,
});

export const notifcation = atom({
  key: 'notification',
  default: null,
});

export const error = atom({
  key: 'error',
  default: null,
});

export const userDeliveryAddress = atom({
  key: 'userDeliveryAddress',
  default: [],
});

export const userInvoiceAddress = atom({
  key: 'userInvoiceAddress',
  default: [],
});

export const languageSelected = atom({
  key: 'languageSelected',
  default: 'fr',
});

export const brandSelected = atom({
  key: 'brandSelected',
  defaut: null,
});

export const paiement = atom({
  key: 'paiement',
  default: false,
});

export const codePromoActif = atom({
  key: 'codePromoActif',
  default: null,
});

export const codePromoValid = atom({
  key: 'codePromoValid',
  default: false,
});

export const numbersPacksInCart = atom({
  key: 'numbersPacksInCart',
  default: 0,
});

export const sousCategorieId = atom({
  key: 'sousCategorieId',
  default: null,
});

export const exportInformations = atom({
  key: 'exportInformations',
  default: null,
});

export const boatIsEdited = atom({
  key: 'boatIsEdited',
  default: false,
});

export const offreExcess = atom({
  key: 'offreExcess',
  default: false,
});

export const dateMaxOffreExcess = atom({
  key: 'dateMaxOffreExcess',
  default: false,
});

export const promoExcessActive = atom({
  key: 'promoExcessActive',
  default: false,
});

export const adminConnectedWithCustomerAccount = atom({
  key: 'adminConnectedWithCustomerAccount',
  default: false,
});
