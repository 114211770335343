import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { adminConnectedWithCustomerAccount, boatSelected, userConnected } from '../../recoil';

export function LoggedRoute({ children }) {
  const user = useRecoilValue(userConnected);
  const adminConnected = useRecoilValue(adminConnectedWithCustomerAccount);
  return (user && user.active) || adminConnected ? children : <Navigate to="/signin" />;
}

export function UnloggedRoute({ children }) {
  const user = useRecoilValue(userConnected);
  const adminConnected = useRecoilValue(adminConnectedWithCustomerAccount);
  return (user && user.active) || adminConnected ? <Navigate to="/" /> : children;
}

export function AgentRoute({ children }) {
  const user = useRecoilValue(userConnected);
  const adminConnected = useRecoilValue(adminConnectedWithCustomerAccount);
  return (user && user.agent) || adminConnected ? children : <Navigate to="/" />;
}

export function BoatSelected({ children }) {
  const boat = useRecoilValue(boatSelected);
  return boat ? children : <Navigate to="/" />;
}
